import { useEffect } from 'react'
import tw, { theme } from 'twin.macro'
import parse from 'html-react-parser'
import { parseContent } from '@components/Blocks'

import FlexSection from '@components/FlexSection'

import { componentLoaded, findColors } from '@utils/functions'

import {
  WpPage_Acfpagefields_Content_TwoColumnsText,
  WpPrograma_Acfpagefields_Content_TwoColumnsText
} from '@_types/graphql-types'

type contentType =
  | WpPage_Acfpagefields_Content_TwoColumnsText
  | WpPrograma_Acfpagefields_Content_TwoColumnsText

const TwoColumnsText = ({
  data,
  index,
  tabs
}: {
  data: contentType
  index: string
  tabs?: boolean
}) => {
  const { text, title, bgColor, text2, tagTitle } = data

  const sectionColors = findColors(bgColor) // find colors from bgColor

  useEffect(() => {
    componentLoaded(title)
  }, [])

  return (
    <FlexSection
      index={index}
      title={title}
      tagTitle={tagTitle}
      colors={sectionColors}
      alignTitle="center"
      paddingBottom
      id={title || ''}
      paddingTop
      tabs={tabs}
      decorator={[
        {
          color: 'white',
          width: 460,
          x: 5,
          y: 2
        }
      ]}
    >
      <div className="flex flex-wrap justify-between">
        {text && (
          <div tw="lg:max-w-md xl:max-w-xl 2xl:max-w-2xl w-full">
            <div
              tw="prose mb-6!"
              css={[sectionColors.text === 'light' && tw`text-white!`]}
            >
              {text && parse(text, { replace: parseContent })}
            </div>
          </div>
        )}

        {text2 && (
          <div tw="lg:max-w-md xl:max-w-xl 2xl:max-w-2xl w-full">
            <div
              tw="prose mb-6!"
              css={[sectionColors.text === 'light' && tw`text-white!`]}
            >
              {text2 && parse(text2, { replace: parseContent })}
            </div>
          </div>
        )}
      </div>
    </FlexSection>
  )
}

export default TwoColumnsText
